<template>
  <div class="switch">
    <label class="switch__label">
      <input
        v-model="model"
        type="checkbox"
        class="switch__input"
      >
      <div class="switch__slider switch__round"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Switcher',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(data) {
        this.$emit('update:checked', data);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.switch {
  display: flex;
  justify-content: flex-start;

  &__label {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 16px;
  }

  &__value {
    font: $font-size-base $font-global;
    color: $color-black;
    cursor: pointer;
  }

  &__input {
    display: none;

    &:checked + .switch__slider {
      background-color: $color-dodger-blue;
    }

    &:focus + .switch__slider {
      box-shadow: 0 0 1px $color-black;
    }

    &:checked + .switch__slider:before {
      transform: translateX(16px);
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-silver-chalice;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 10px;
      width: 10px;
      left: 3px;
      bottom: 3px;
      background-color: $color-white;
      transition: .3s;
    }

    &.switch__round {
      border-radius: 34px;
    }

    &.switch__round:before {
      border-radius: 50%;
    }
  }

  &__value {
    margin-left: 43px;
  }
}

</style>
